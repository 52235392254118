import React, { Component } from 'react';
import countapi from 'countapi-js';

class Visit extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  async callAPI() {

    if (window.location.href.includes('mostafamilani.ir')) {

      await

        fetch("https://server-mostafamilani.iran.liara.run/record/63dbadd57aaab8d2b7b43e7f", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.text())
          .then(res => this.setState({ apiResponse0: JSON.parse(res).contatore + 1 }));

      fetch("https://server-mostafamilani.iran.liara.run/update/63dbadd57aaab8d2b7b43e7f", {
        method: "POST",
        body: JSON.stringify({
          contatore: Number(this.state.apiResponse0),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse: res }));


      fetch("https://server-mostafamilani.iran.liara.run/record/63dbadd57aaab8d2b7b43e7f", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse2: JSON.parse(res).contatore }));

    }

  }

  componentWillMount() {
    this.callAPI();
  }

  render() {

    return (
      <span style={{paddingLeft: '15px'}} title="Numero delle visite totali sul sito">👁 {this.state.apiResponse2}</span>
    );

  }
}

//   componentDidMount() {
//     countapi.visits('mostafamilani.ir').then((result) => {
//       this.setState({ visite: result.value });
//     });
//   }

//   render() {

//     return (
//       <span style={{paddingLeft: '15px'}} title="Numero delle visite totali sul sito">👁 {this.state.visite}</span>
//     );

//   }
// }

export default Visit;